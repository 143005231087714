import { cn } from "@bem-react/classname";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Section from "../../landingnewcomponents/Section/Landing";
import FooterNavigation from "./components/FooterNavigation";
import "./Footer.scss";
import skolkovo from './images/skolkovo.svg'
import Skolkovo from "./components/Skolkovo";

const Footer = ({navigation, location}) => {
    const classItem = cn('Footer');

    const intl = useIntl();
    return (
        <>
            <footer className={classItem()}>
                <Section>
                    <div className={classItem('Content')}>
                        <div className={classItem('Menu')}>
                            <FooterNavigation items={navigation.left}/>
                        </div>
                        <div className={classItem('Menu')}>
                            <FooterNavigation items={navigation.center}/>
                        </div>
                        <div className={classItem('Menu')}>
                            <FooterNavigation items={navigation.right}/>
                        </div>
                        <div className={classItem('Menu')}>
                            <FooterNavigation languageSwitch items={[
                                {
                                    text: 'English',
                                    link: '/en' + location,
                                    active: intl.locale === 'en'
                                },
                                {
                                    text: 'Русский',
                                    link: '/ru' + location,
                                    active: intl.locale === 'ru'
                                }
                            ]}/>
                            <br/>
                            <a href="https://www.producthunt.com/posts/viewst-workspace?utm_source=badge-featured&amp;utm_medium=badge&amp;utm_souce=badge-viewst-workspace"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=306407&amp;theme=light"
                                    alt="Viewst Workspace - Hit deadlines, scale content &amp; boost creative production | Product Hunt"
                                    style={{width: '250px', height: '54px'}} target="_blank" rel="noreferrer" width="250" height="54"/>
                            </a>
                            {intl.locale === 'ru' && <Skolkovo/>}
                        </div>
                    </div>
                    <div className={classItem('BottomContent')}>
                        <FormattedMessage id="copyright"/>
                    </div>
                </Section>
            </footer>
        </>
    )
};

export default Footer;
