import { useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { GridList, GridListItem } from '../landingnewcomponents/GridList/Landing';
import Section from "../landingnewcomponents/Section/Landing";
import SubTitle from '../landingnewcomponents/SubTitle/Landing';
import Title, { TITLE_COLORS } from '../landingnewcomponents/Title/Landing';
import Layout from "../layouts/layout";
import ContactUsSection from '../sections/contact-us';
import "./../common/styles.scss";


const HelpPage = ({ location }) => {
    const { formatMessage, locale } = useIntl();

    const data = {
        "Getting Started": {
            "Quick tour": "#",
            "Interface overview": "#",
            "Projects screen": "#",
            "Create a project": "#",
            "Saving": "#",
            "Rename a project": "#",
            "Delete a project": "#",
            "Add a size": "#",
        },
        "Design": {
            "Change language and theme": "#"
        },
        "Animation": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        },
        "Project settings": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        },
        "Downloading": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        },
        "Pro features": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        },
        "Account": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        },
        "Advantages": {
            "Quick toor 1": "#",
            "Quick toor 2": "#",
            "Quick toor 3": "#",
            "Quick toor 4": "#",
            "Rename a project 5": "#",
            "Rename a project 6": "#",
            "Rename a project 7": "#",
            "Rename a project 8": "#"
        }
    }


    return (
        <Layout location={location}>
            <Section>
                <Breadcrumbs
                    items={[{ title: "Viewst", url: "/" }, { title: "Help", url: "/help/" }]}
                />
                <Title color={TITLE_COLORS.DarkGray}>Help</Title>

                {Object.entries(data).map(([key, value]) => (
                    <>
                        <SubTitle with_bg thin color={TITLE_COLORS.White}>{key}</SubTitle>
                        <GridList className='GridList_l2_m2_s1'>
                            {Object.entries(value).map(([key, value]) => {
                                return (
                                    <>
                                        <GridListItem><a className={'LandingLink LandingLink_large'} href={value}>{key}</a></GridListItem>
                                    </>
                                )
                            })}
                        </GridList>
                    </>
                )
                )}

            </Section>
            <ContactUsSection />
        </Layout >
    )
}

export default HelpPage;
