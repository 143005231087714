import "@formatjs/intl-pluralrules/polyfill";
import { graphql, useStaticQuery } from "gatsby";
import { navigate, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import React, { useLayoutEffect } from "react";
import { Helmet } from 'react-helmet';
import Footer from "../components/Footer";
import Header from "../components/Header";
import menu from "./menu";
import { PixelsAndAnalytics } from "../components/PixelsAndAnalytics/PixelsAndAnalytics";


const Layout = ({ location, children }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            langs
          }
        }
      }
    }
  `)
	const intl = useIntl();

	const { langs } = data.site.siteMetadata.languages;
	const langKey = intl.locale;
	const DARK_MENU_LOCATIONS = ['/', '/pricing/', '/us/'];
	const LANG_TO_LOCALE = { 'en': 'en_US', 'ru': 'ru_RU' }
	const strippedLocation = location.pathname.replace(`/${langKey}`, '').replace('/', '');

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: langKey,
				}}
			>
				<title>{intl.formatMessage({ id: "head.title" })}</title>
				<link rel="preload" href="/fonts/stylesheet.css" as="style" />
				<meta name="description" content={intl.formatMessage({ id: "head.description" })}></meta>
				<meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
				<link rel="canonical" href={`https://viewst.com/${langKey}`} />
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
				<meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>

				{/* OG */}
				<meta property="og:locale" content={LANG_TO_LOCALE[langKey]} />
				<meta property="og:locale:alternate" content="ru_RU" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={intl.formatMessage({ id: "head.og.title" })} />
				<meta property="og:description" content={intl.formatMessage({ id: "head.og.description" })} />
				<meta property="og:url" content={`https://viewst.com/${langKey}`} />
				<meta property="og:site_name" content="Viewst" />
				<meta property="article:modified_time" content="2021-04-23T20:00:12+00:00" />
				<meta property="og:image" content="https://viewst.com/images/og-1.png" />
				<meta property="og:image:width" content="1360" />
				<meta property="og:image:height" content="896" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:image" content="https://viewst.com/images/og-1.png" />

				<link rel="dns-prefetch" href="//js.hs-scripts.com" />
				<link rel="dns-prefetch" href="//s.w.org" />
				<link rel="alternate" type="application/rss+xml" title={intl.formatMessage({ id: "head.rss.feed" })} href="https://viewst.com/en/feed/" />
				<link rel="alternate" type="application/rss+xml" title={intl.formatMessage({ id: "head.rss.commentsFeed" })} href="https://viewst.com/en/comments/feed/" />
			</Helmet>
			<Header menu={menu[langKey].header} lightPage={!DARK_MENU_LOCATIONS.includes('/' + strippedLocation)} />
			<main>
				{children}
			</main>
			<Footer navigation={menu[langKey].footer} location={'/' + strippedLocation} />
			<link rel="stylesheet" href="/fonts/stylesheet.css" type="text/css" />
			<PixelsAndAnalytics/>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	location: PropTypes.any.isRequired,
}

export default Layout
