import { cn } from '@bem-react/classname';
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import Wrapper from '../../../Wrapper';
import Link from '../Link';
import './MobileMenu.scss';


const MobileMenu = ({ isVisible, links, onClose }) => {
    const classItem = cn('MobileMenu');
    const intl = useIntl();

    return (
        <div className={classItem({ isVisible })}>
            <Wrapper>
                <ul className={classItem('List')}>
                    { links.map((link, key) =>
                        <li key={key} className={classItem('ListItem')}>
                            <Link className={classItem('Link')} href={link.href}><FormattedMessage id={link.trlKey} /></Link>
                        </li>
                    )}
                    <li className={classItem('ListItem')}>
                        <a
                            className="Link Link_button"
                            href={`https://wizard.viewst.com/signup?lang=${intl.locale}`}
                            target="_blank" title="Start designing" rel="noreferrer"

                        >
                            Start designing
                        </a>
                    </li>
                </ul>
            </Wrapper>
            { isVisible && <div tabIndex={0} onKeyUp={() => onClose() } onClick={() => onClose()} className={classItem('CloseArea')} role="button">Close modal</div>}
        </div>
    )
};

export default MobileMenu;
