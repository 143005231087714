import { cn } from '@bem-react/classname';
import React from 'react';
import './link.scss';


const PromoLink = ({href='#', buttonLike=false, linkLike=false, small=false, medium=false, children}) => {
    const linkClassItem = cn('PromoLink');
    return <a href={href} target="_blank" rel="noopener noreferrer" role="button"
              className={linkClassItem('', { buttonLike, small, medium, linkLike})}>{children}</a>

}

const LandingLink = ({href = '#', buttonLike = false, linkLike = false, small = false, children}) => {
    const linkClassItem = cn('LandingLink');
    return <a href={href} target="_blank" role="button" rel="noopener noreferrer"
              className={linkClassItem('', {buttonLike, small, linkLike}) + ' g-pin-right'}>{children}</a>

}

export { LandingLink, PromoLink };
