import { cn } from '@bem-react/classname';
import { Link as InternalLink } from "gatsby-plugin-react-intl";
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import './Link.scss';

const Link = ({ href, title, children, target = '_self', rel = null, button = false }) => {
    const classItem = cn('Link');
    return (
        <GatsbyLink
            className={classItem('', { button })}
            activeClassName="Link_active"
            to={href}
            target={target}
            title={title}
            rel={rel}>
            {children}
        </GatsbyLink>
    )
};

export default Link;
