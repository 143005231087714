import { cn } from '@bem-react/classname';
import { FormattedMessage } from "gatsby-plugin-react-intl";
import React from 'react';
import Link from '../Link';
import './Navigation.scss';


const Navigation = ({ links }) => {
    const classItem = cn('Navigation');

    return (
        <nav className={classItem()}>
            <ul className={classItem('List')}>
                { links.map((link, key) =>
                <li key={key} className={classItem('ListItem')}>
                        <Link href={link.href} title="Pricing"><FormattedMessage id={link.trlKey} /></Link>
                </li>
                )}
            </ul>
        </nav>
    )
}

export default Navigation;
