import { cn } from '@bem-react/classname';
import { Link as InternalLink } from "gatsby-plugin-react-intl";
import React from 'react';
import './breadcrumbs.scss';

/**
 *
 * @param {Object} props
 * @param {Array.<{title: string, url: string}>} props.items
 *
 * @returns
 */
const Breadcrumbs = ({ items = [], inverted=false}) => {
    const breadcrumbs = cn('Breadcrumbs');
    return <div className={breadcrumbs()}>{
        items.map((breadcrumb, idx) => {
            return <InternalLink key={idx} className={breadcrumbs('Item', {inverted})} to={breadcrumb.url}>{breadcrumb.title}</InternalLink>
        })
    }</div>
}

export default Breadcrumbs
