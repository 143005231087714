import { cn } from '@bem-react/classname';
import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import './Actions.scss';


const Actions = ({intl}) => {
    const classItem = cn('Actions');

    return (
        <div className={classItem()}>
            {/* <div className={classItem('Col')}></div> */}
            <div className={classItem('Col')}>
                <a className="Link Link_button" href={`https://wizard.viewst.com/signup?lang=${intl.locale}`} target="_blank" title="Start designing" rel="noreferrer">
                    <FormattedMessage id="start_designing" />
                </a>
            </div>
        </div>
    )
};

export default injectIntl(Actions);
