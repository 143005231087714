const MENU = {
    en: {
        header: [
            {
                href: "/pricing/",
                trlKey: "menu.pricing"
            },
            {
                href: "/learn/",
                trlKey: "menu.learn"
            },
            {
                href: "/tutorials/",
                trlKey: "menu.tutorials"
            },
            {
                href: "/news/",
                trlKey: "menu.news"
            },
            {
                href: "/us/",
                trlKey: "menu.us"
            },
        ],
        footer: {
            left: [
                {
                    link: "/pricing/",
                    text: "Pricing"
                },
                {
                    link: "/learn/",
                    text: "Learn"
                },
                {
                    link: "/us/",
                    text: "About"
                },
                {
                    link: "/contacts/",
                    text: "Contacts"
                },
            ],
            center: [
                {
                    text: 'Help',
                    link: '/help/'
                },
                {
                    text: 'Privacy Policy',
                    link: '/privacy-policy/'
                },
                {
                    text: 'Terms of use',
                    link: '/terms-and-conditionals/'
                },
                {
                    text: 'Cookies Policy',
                    link: '/cookies-policy/'
                },
                {
                    text: 'Public roadmap',
                    link: 'https://trello.com/b/jLGm6aFc'
                }
            ],
            right: [
                {
                    text: 'Instagram',
                    link: 'https://www.instagram.com/viewst_global/'
                },
                {
                    text: 'Youtube',
                    link: 'https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA'
                },
                {
                    text: 'Facebook',
                    link: 'https://www.facebook.com/ViewstAds/'
                },
                {
                    text: 'LinkedIn',
                    link: 'https://www.linkedin.com/company/viewstadsbuilder/'
                },
                {
                    text: 'Twitter',
                    link: 'https://twitter.com/ViewstBuilder'
                }
            ]
        },
    },
    ru: {
        header: [
            {
                href: "/pricing/",
                trlKey: "menu.pricing"
            },
            {
                href: "/learn/",
                trlKey: "menu.learn"
            },
            {
                href: "/tutorials/",
                trlKey: "menu.tutorials"
            },
            {
                href: "/news/",
                trlKey: "menu.news"
            },
            {
                href: "/us/",
                trlKey: "menu.us"
            },
        ],
        footer: {
            left: [
                {
                    link: "/pricing/",
                    text: "Тарифы"
                },
                {
                    link: "/learn/",
                    text: "Статьи"
                },
                {
                    link: "/us/",
                    text: "О нас"
                },
            ],
            center: [
                {
                    text: 'Условия использования',
                    link: '/terms-and-conditionals/'
                },
                {
                    text: 'Политика конфиденциальности',
                    link: '/privacy-policy/'
                },
                {
                    text: 'Публичный роадмап',
                    link: 'https://trello.com/b/jLGm6aFc'
                },
            ],
            right: [
                {
                    text: 'Youtube',
                    link: 'https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA'
                },
                {
                    text: 'ВКонтакте',
                    link: 'https://vk.com/viewst_russia'
                },
                {
                    text: 'Instagram',
                    link: 'https://www.instagram.com/viewst_cis/'
                },
                {
                    text: 'Facebook',
                    link: 'https://www.facebook.com/viewstcis/'
                },
                {
                    text: 'Twitter',
                    link: 'https://twitter.com/ViewstBuilder'
                },
                {
                    text: 'LinkedIn',
                    link: 'https://www.linkedin.com/company/viewstadsbuilder/'
                },
            ]
        }
    }
}


export default MENU;
