import { cn } from '@bem-react/classname';
import React from 'react';
import './title.scss';

export const TITLE_COLORS = {
    DarkGray: 'dark-gray',
    DarkBlue:'dark-blue',
    LightBlue: 'light-blue',
    Violet: 'violet',
    Pink: 'pink',
    White: 'white',
    Black: 'black',
}

const Title = ({standout, color=TITLE_COLORS.Black, centered=false, children}) => {
    const titleClass = cn('Title');

    return (<h1 className={titleClass('', { standout, color: color, centered})}>{children}</h1>);
};

export default Title
