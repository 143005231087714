import React from 'react';
import { cn } from '@bem-react/classname';

import './Wrapper.scss';

const Wrapper = ({ children }) => {
    const classItem = cn('Wrapper')

    return (
        <div className={classItem()}>
            { children }
        </div>
    )
}

export default Wrapper;