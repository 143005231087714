import { cn } from '@bem-react/classname';
import { Link as InternalLink, useIntl } from "gatsby-plugin-react-intl";
import React from 'react';
import './FooterNavigation.scss';

const FooterNavigation = ({ items, languageSwitch }) => {
    const classItem = cn('FooterNavigation');
    const intl = useIntl();

    return (
        <div className={classItem()}>
            <ul className={classItem('List')}>
                {items.map((item, idx) =>
                    <li key={idx} className={classItem('ListItem')}>
                        {item.link.startsWith('http') || languageSwitch?
                            <a className= { classItem('Link', {active: item.active })} href={item.link}>{item.text}</a> :
                            <InternalLink className={classItem('Link', { active: item.active })} to={item.link}>{item.text}</InternalLink>
                        }
                    </li>
                )}
            </ul>
        </div>
    )
}

export default FooterNavigation;
