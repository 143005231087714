import { cn } from '@bem-react/classname';
import React from 'react';
import './grid-list.scss';

const gridList = cn('GridList');
const GridList = ({ children, className = '', tile = false}) => {
    return (
        <ol className={[gridList('', {tile}), className].join(' ')}>
            {children}
        </ol>
    )
}

const GridListItem = ({ordered, children}) => {
    // const classItem = cn('GridList', ['Item']);

    return (
        <li className={gridList('Item', {withMarker: ordered})}>{children}</li>
    )
}

const GridListItemImage =  ({sideSize=100, alt='', ...props}) => {
    return <img className={gridList('Item-Image')} alt={alt} {...props} style={{width: sideSize, height:sideSize}}/>
}
const GridListItemTitle = ({children}) => {
    return <h3>{children}</h3>
}
const GridListItemParagraph = ({children}) => {
    return <p>{children}</p>
}

export { GridList, GridListItem, GridListItemImage, GridListItemParagraph, GridListItemTitle };

