import { FormattedMessage } from "gatsby-plugin-react-intl";
import React from 'react';
import { PromoLink } from '../landingnewcomponents/Link/Landing';
import Section, { SECTION_COLOR } from '../landingnewcomponents/Section/Landing';
import SubTitle from '../landingnewcomponents/SubTitle/Landing';
import Title, { TITLE_COLORS } from '../landingnewcomponents/Title/Landing';

const ContactUsSection = () => {
    return (
        <Section centered color={SECTION_COLOR.DarkGray}>
            <Title color={TITLE_COLORS.White} centered>
                <FormattedMessage id="common.sections.contactUs.title" defaultMessage="Can’t find your answer?"/>
            </Title>
            <SubTitle centered>
                <FormattedMessage id="common.sections.contactUs.subtitle" defaultMessage="Contact us and we’ll get back to you as soon as we can."/>
            </SubTitle>
            <PromoLink buttonLike href={"https://viewst.com/contacts/"}>
                <FormattedMessage id="common.sections.contactUs.actionContact" defaultMessage="Contact"/>
            </PromoLink>
        </Section>
    )
}

export default ContactUsSection;
