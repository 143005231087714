import React from "react";
import { cn } from "@bem-react/classname";
import "./Skolkovo.scss";

const Skolkovo = () => {
  const classItem = cn("Skolkovo");

  return (
    <div className={classItem()}>
      <a href={"https://sk.ru/"}><img src="/images/skolkovo.svg" alt="Skolkovo" className={classItem("Image")} /></a>
    </div>
  );
};

export default Skolkovo;