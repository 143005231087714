import { cn } from "@bem-react/classname";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import Actions from "./components/Actions";
import MobileMenu from "./components/MobileMenu";
/*** Components ***/
import Navigation from "./components/Navigation";
import "./Header.scss";


const Header = ({ menu, lightPage = false }) => {
  const classItem = cn("Header");
  const [menuVisible, setMenuVisible] = useState(false);
  const [visible, setVisible] = useState(true);

  const setVisibleOnScroll = useCallback((e) => {
    const scrollTop = window.scrollY;
    const { deltaY } = e;
    if (deltaY > 0) {
      setVisible(false);
    }

    if (deltaY < 0) {
      setVisible(true);
    }

    if (scrollTop <= 30) {
      setVisible(true);
    }
  }, [setVisible]);

  const attachEvents = useCallback(() => {
    document.addEventListener("wheel", setVisibleOnScroll);
  }, [setVisibleOnScroll]);

  useEffect(() => {
    attachEvents();

    return function() {
      document.removeEventListener("wheel", setVisibleOnScroll);
    };
  }, [attachEvents, setVisibleOnScroll]);

  return (
    <>
      <div className={classItem("Spacer")}></div>
      <header className={classItem({ visible, white: lightPage })}>
        <Wrapper>
          <div className={classItem("Container")}>
            <div className={classItem("Logotype")}>
              <a href={"/"}>
                <img alt="Viewst" width="100%" height="auto"
                     src={"https://viewst.com/images/logotype.png"} />
              </a>
            </div>
            <div className={classItem("Navigation")}>
              <Navigation links={menu} />
            </div>
            <div className={classItem("Actions")}>
              <Actions />
              <button onClick={() => setMenuVisible(!menuVisible)}
                      className={classItem("Burger", { isVisible: menuVisible })}>
                <span></span><span></span><span></span>
              </button>
            </div>
          </div>
        </Wrapper>
        <MobileMenu isVisible={menuVisible} onClose={() => setMenuVisible(false)} links={menu} />
      </header>
    </>
  );
};

Header.propTypes = {
  menu: PropTypes.array.isRequired
};

export default Header;
